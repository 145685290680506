<template>
  <div>
    <b-modal ref="my-modal" hide-footer title="New Third Party Category" size="lg">
      <validation-observer ref="UploadForm" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleNewCategorySubmit">
          <b-form-group label="Name">
            <template slot="label">
              Name
              <span class="text-danger">*</span>
            </template>
            <validation-provider #default="{ errors }" name="Name" rules="required">
              <b-form-input v-model="newCategory.title" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Description">
            <validation-provider #default="{ errors }" name="Description">
              <b-form-textarea id="textarea" v-model="newCategory.description" rows="3" max-rows="6" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button type="submit" variant="primary" class="mt-3 mr-1" :disabled="invalid">
            <feather-icon icon="CheckIcon" class="mr-50" />
            Submit
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-sidebar id="sidebar-backdrop" sidebar-class="sidebar-lg" bg-variant="white" backdrop right no-header shadow lazy>
      <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="secondary">
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div style="width: 70%" class="d-flex flex-column justify-content-center align-items-start">
              <h4 class="font-weight-bolder">
                <span class="align-middle">Add New Third Party</span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button size="sm" @click="closeSidebar()" variant="outline-secondary"><feather-icon size="24"
                  icon="XIcon" /></b-button>
            </div>
          </div>
          <div class="p-1">
            <validation-observer ref="observer">
              <b-form-group label="Third Party" label-for="defaultLabel">
                <template slot="label">
                  Third Party
                  <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Third Party" rules="required">
                  <b-form-input id="defaultLabel" placeholder="Third Party" v-model="vendor" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="First Name" label-for="defaultLabel">
                <template slot="label">
                  First Name
                  <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="First Name" rules="required">
                  <b-form-input id="defaultLabel" placeholder="First Name" v-model="firstname" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Last Name" label-for="defaultLabel">
                <template slot="label">
                  Last Name
                  <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Last Name" rules="required">
                  <b-form-input id="defaultLabel" placeholder="Last Name" v-model="lastname" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Email" label-for="defaultLabel">
                <template slot="label">
                  Email
                  <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input id="defaultLabel" placeholder="Email" v-model="email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Phone Number" label-for="defaultLabel">
                <b-form-input id="defaultLabel" placeholder="Phone Number" v-model="phonenumber" />
              </b-form-group>
              <div class="d-flex align-items-start justify-content-between">
                <b-form-group label="Category" class="w-75">
                  <template slot="label">
                    Category
                    <span class="text-danger">*</span>
                  </template>
                  <validation-provider #default="{ errors }" name="Category" :rules="{
        required: true,
      }">
                    <vue-autosuggest :suggestions="formData.category.suggestions" :limit="5"
                      v-model="formData.category.selectedCategoryName" id="autosuggest__input"
                      :input-props="formData.category.inputProps" @input="getCategoriesBySearchPhraseAndSetOptions"
                      @selected="onCategorySelected" :get-suggestion-value="getCategorySuggestionValue">
                      <template slot-scope="{ suggestion }" style="display: flex; align-items: center">
                        <span style="{ display: 'flex', color: 'navyblue'}">{{ suggestion.item.title }}
                        </span>
                      </template>
                    </vue-autosuggest>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div class="d-flex flex-row flex-wrap align-items-center justify-content-start mt-75">
                    <b-badge class="mr-25 mt-25" v-for="category in formData.category.selectedCategories"
                      variant="light-primary" :key="category._id">{{ category.title
                      }}<feather-icon @click="handleRemoveCategory(category._id)"
                        class="text-danger ml-25 cursor-pointer" icon="XIcon" /></b-badge>
                  </div>
                </b-form-group>

                <div class="h-100 d-flex align-items-center justify-content-center">
                  <b-button @click="handleNewCategoryClick" variant="success" style="margin-top: 26px"><feather-icon
                      icon="PlusIcon" class="mr-50" />Add
                    Category</b-button>
                </div>
              </div>
              <b-form-group label="Tags" class="w-100">
                <vue-autosuggest :suggestions="tagOptions" :limit="5" v-model="tagName" id="autosuggest__input"
                  :input-props="tagInputProps" @input="getAssetTagsBySearchPhraseAndSetData" @selected="onTagSelected"
                  :get-suggestion-value="getTagSuggestionValue">
                  <template slot-scope="{ suggestion }" style="display: flex; align-items: center">
                    <span style="{ display: 'flex', color: 'navyblue'}">{{
        suggestion.item.title
      }}</span>
                  </template>
                </vue-autosuggest>
                <div class="d-flex flex-wrap align-items-center justify-content-start mt-75">
                  <template v-for="(value, i) in formData.selectedTags">
                    <b-badge :key="i" variant="light-primary" class="mr-1">
                      <span>{{ value.tag_title }}</span>
                      <feather-icon @click="removeTag(value.tag_title)" icon="XCircleIcon"
                        class="cursor-pointer ml-50 text-danger" />
                    </b-badge>
                  </template>
                </div>
              </b-form-group>

              <button class="btn btn-primary mr-1 mt-1" @click="createVendor">
                Add Third Party
              </button>
              <button class="btn btn-secondary mt-1" @click="closeAddVendor">
                Cancel
              </button>
            </validation-observer>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    <b-sidebar id="sidebar-edit" sidebar-class="sidebar-lg" bg-variant="white" backdrop right no-header shadow>
      <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="secondary">
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div style="width: 70%" class="d-flex flex-column justify-content-center align-items-start">
              <h4 class="font-weight-bolder">
                <span class="align-middle">Third Party Details</span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button size="sm" @click="closeEditLegal()" variant="outline-secondary"><feather-icon size="24"
                  icon="XIcon" /></b-button>
            </div>
          </div>

          <div class="p-1">
            <h4>{{ vendordetail.name }}</h4>
            <h6 class="mt-1">Users</h6>
            <table role="table" class="table table-hover mt-1">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">#</th>
                  <th role="columnheader" scope="col">First Name</th>
                  <th role="columnheader" scope="col">Last Name</th>
                  <!-- <th role="columnheader" scope="col">Email</th> -->
                </tr>
              </thead>
              <tbody role="rowgroup">
                <!-- {{this.legallist}} -->
                <tr v-for="(user, index) in vendordetail.users" :key="user._id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <template v-if="user.firstname && user.firstname != null">
                      {{ user.firstname }}
                    </template>
                    <b-badge v-else variant="danger">Deleted User</b-badge>
                  </td>
                  <td>
                    <template v-if="user.lastname && user.lastname != null">
                      {{ user.lastname }}
                    </template>
                    <b-badge v-else variant="danger">Deleted User</b-badge>
                  </td>
                  <!-- <td>{{ user.email }}</td> -->
                  <!-- <td>
                    <button class="btn btn-primary btn-sm" @click="vendorDetails(vendor)">View Details</button>
                </td> -->
                </tr>

                <!-- {{
                vendorslist
              }} -->
              </tbody>
            </table>
            <!-- {{ vendordetail }} -->
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    <b-sidebar id="sidebar-applicable" sidebar-class="sidebar-lg" bg-variant="white" backdrop right no-header shadow>
      <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="secondary">
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div style="width: 70%" class="d-flex flex-column justify-content-center align-items-start">
              <h4 class="font-weight-bolder">
                <span class="align-middle">Applicability Review</span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button size="sm" @click="closeApplicable()" variant="outline-secondary"><feather-icon size="24"
                  icon="XIcon" /></b-button>
            </div>
          </div>

          <div class="p-1">
            <b-form-group label="Applicability" label-for="defaultLabel">
              <select name="" id="" class="form-control" v-model="applicability">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </b-form-group>
            <b-form-group label="Applicability Note" label-for="defaultLabel">
              <b-form-textarea id="textarea-default" placeholder="Enter Note" rows="3" v-model="applicability_note" />
            </b-form-group>
            <b-form-group label="Assessment Date" label-for="defaultLabel">
              <flat-pickr v-model="assessmentDate" class="form-control" />
            </b-form-group>

            <button class="btn btn-primary mr-1" @click="AddReview">
              Add Review
            </button>
            <button class="btn btn-secondary">Cancel</button>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>

    <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="secondary">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <b-button @click="openSidebar" class="mb-75" variant="success"><feather-icon class="mr-75"
                icon="PlusIcon" />New
              Third Party
            </b-button>
          </div>

          <table role="table" class="table table-hover mt-1">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">#</th>
                <th role="columnheader" scope="col">Name</th>
                <th role="columnheader" scope="col">Action</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <!-- {{this.legallist}} -->
              <template v-if="vendorslist && vendorslist.length > 0">
                <tr v-for="(vendor, index) in vendorslist" :key="vendor._id" @click="vendorDetails(vendor)">
                  <th style="max-width:90px;">
                    {{ (currentPage - 1) * pagination.perPage + index + 1 }}
                  </th>
                  <td style="width: 20vw;">{{ vendor.name }}</td>
                  <td>
                    <button class="btn btn-primary btn-sm" @click.stop="vendorDetails(vendor)">
                      View Details
                    </button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <empty-table-section title="Third Parties Not Found">
                  <template #content>
                    <p class="font-weight-bold text-center">
                      It seems like there are no Third Parties at the moment.
                    </p>
                  </template>
                </empty-table-section>
              </template>

              <!-- {{
                vendorslist
              }} -->
            </tbody>
          </table>

          <div class="mt-3">
            <div class="row">
              <div class="d-flex align-items-center justify-content-center col-12">
                <b-pagination v-model="currentPage" :per-page="pagination.perPage" :total-rows="pagination.rows"
                  aria-controls="my-table"></b-pagination>
                <!-- <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  hide-goto-end-buttons
                  :total-rows="lastPage * perPage"
                  class="float-right"
                /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BSidebar,
  BOverlay,
  BAvatar,
  BCollapse,
  BBadge,
  BModal,
  BCol,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
import RiskAssessmentMixins from "@//mixins/RiskAssessmentMixins";
import ResponseMixins from "../../mixins/ResponseMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { VueAutosuggest } from "vue-autosuggest";
import { required, email } from "@validations";
import DonutBar from "@/components/DonutBar.vue";
import UtilsMixins from "../../mixins/UtilsMixins";
// import ThirdPartyRisksMixins from "../../../mixins/ThirdPartyRisksMixins";

import ThirdPartyRisksRAMixins from "../../mixins/ThirdPartyRisksRAMixins";
import VendorMixins from "../../mixins/VendorMixins";
import TagMixins from "../../mixins/TagMixins";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
export default {
  components: {
    BPagination,
    BButton,
    BSidebar,
    BFormInput,
    BOverlay,
    BBadge,
    BAvatar,
    BCollapse,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    DonutBar,
    flatPickr,
    ToastificationContent,
    VueAutosuggest,
    EmptyTableSection
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showOverlay: false,
      pagination: {
        lastPage: null,
        perPage: 10,
        rows: 1,
      },
      filters: {
        page: 1,
      },
      currentPage: 1,
      newRiskAssessment: {
        title: null,
        notes: null,
      },
      assessments: null,
      dateDefault: null,
      title: "",
      content: "",
      authority: "",
      internalpoc: "",
      type: "",
      country: "",
      userslist: [],
      legallist: [],
      legalid: "",
      legaldetails: [],
      authorities: [],
      applicability: "",
      applicability_note: "",
      assessmentDate: "",
      countries: [],
      industries: [],
      countryy: "",
      industryy: "",
      vendor: "",
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      vendorslist: [],
      vendordetail: [],
      newCategory: {
        title: null,
        description: null,
      },
      formData: {
        firstName: null,
        lastName: null,
        category: {
          suggestions: [],
          selectedCategory: null,
          selectedCategoryName: null,
          selectedCategories: [],
          inputProps: {
            class: "form-control",
            placeholder: "Search Categories..",
          },
        },
        email: null,
        mobile: null,
        selectedTags: [],
        vendor: null,
        shouldInvite: false,
      },
      tagOptions: [],
      tagName: "",
      tagInputProps: {
        class: "form-control",
        placeholder: "Search & Select Tags..",
      },
      required,
      email,
    };
  },
  mixins: [
    ResponseMixins,
    RiskAssessmentMixins,
    UtilsMixins,
    ThirdPartyRisksRAMixins,
    VendorMixins,
    TagMixins,
  ],
  watch: {
    // filters: {
    // page: function (newValue) {
    //     this.getVendorsList(newValue);
    //   },
    //   deep: true,
    // },

    currentPage(value) {
      this.getVendorsList(value);
    },
  },
  mounted() {
    this.getVendorsList(this.filters.page);
  },
  methods: {
    getAssetTagsBySearchPhraseAndSetData(searchPhrase) {
      if (searchPhrase !== "") {
        this.debounceFunction(() => {
          this.getAssetTagsBySearchPhrase(searchPhrase)
            .then((res) => {
              this.tagOptions = [];
              const newTags = res.data.data.data;
              if (newTags.length == 0) {
                newTags.push({ title: searchPhrase, is_new: true });
              }
              this.tagOptions.push({ name: "tags", data: newTags });
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      } else {
        this.tagOptions = [];
      }
    },
    onTagSelected(val) {
      if (val) {
        const selectedVal = val.item;
        const tagIndex = this.formData.selectedTags.findIndex(
          (tag) => tag.tag_title === selectedVal.title
        );
        if (tagIndex === -1) {
          this.tagName = "";
          this.formData.selectedTags.push({
            tag_id: selectedVal._id,
            tag_title: selectedVal.title,
            is_new: selectedVal._id ? false : true,
          });
        }
      }
    },

    removeTag(tagName) {
      let newSelectedTags = this.formData.selectedTags.filter(
        (tag) => tag.tag_title !== tagName
      );
      this.formData.selectedTags = newSelectedTags;
    },

    getTagSuggestionValue(suggestion) {
      return suggestion.item.title;
    },

    handleRemoveCategory(categoryId) {
      let newCategories = this.formData.category.selectedCategories.filter(
        (category) => category._id !== categoryId
      );
      this.formData.category.selectedCategories = newCategories;
    },

    getCategoriesBySearchPhraseAndSetOptions(params) {
      console.log(params);
      this.debounceFunction(() => {
        this.getAssessmentCategories(params)
          .then((res) => {
            this.formData.category.suggestions.push({
              name: "categories",
              data: res.data.data.data,
            });
          })
          .catch((err) => {
            this.handleError(err);
          });
      });
    },

    onCategorySelected(value) {
      this.formData.category.selectedCategories.push(value.item);
      this.formData.category.selectedCategoryName = value.item.title;
    },

    getCategorySuggestionValue(suggestion) {
      return suggestion.item.name;
    },

    handleNewCategoryClick() {
      this.newCategory = {
        title: null,
        description: null,
      };
      this.showModal();
    },
    handleNewCategorySubmit() {
      this.showOverlay = true;
      this.createNewVendorCategory(this.newCategory)
        .then((res) => {
          this.hideModal();
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    vendorDetails(vendor) {
      this.vendordetail = vendor;
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
    },
    getCountry() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/countries`,
      };
      this.$http(options)
        .then((res) => {
          this.countries = res.data.data.data;

          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getIndustry() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/industries`,
      };
      this.$http(options)
        .then((res) => {
          this.industries = res.data.data.data;

          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    AddReview() {
      const data = {
        applicability: this.applicability,
        applicability_note: this.applicability_note,
        assessment_date: this.assessmentDate,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/legal-registers/${this.legalid}/assessments`,
      };
      this.$http(options)
        .then((res) => {
          //   this.sidebar_bulkupdate = false;
          this.closeApplicable();
          this.getLegalList();
          this.applicability = "";
          this.applicability_note = "";
          this.assessmentDate = "";

          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ApplicabilityReview(legalid) {
      this.legalid = legalid;
      this.$root.$emit("bv::toggle::collapse", "sidebar-applicable");
    },
    closeApplicable() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-applicable");
    },
    viewLegalRegister(legalid) {
      this.$router.push({
        name: "legalassessment",
        params: { id: legalid },
      });
    },
    getAuthority() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSFURL + `/authorities`,
      };
      this.$http(options)
        .then((res) => {
          this.authorities = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    load() {
      //   this.getRiskAssessmentsAndSetData(this.filters);
      this.getUsers();
      this.getLegalList();
    },
    createVendor() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          const bodyFormData = new FormData();

          if (this.formData.category.selectedCategories.length > 0) {
            this.formData.category.selectedCategories.map((category, i) => {
              bodyFormData.append(`vendor_category_ids[${i}]`, category._id);
            });
          }
          this.formData.selectedTags.map((tag, i) => {
            bodyFormData.append(`tags[${i}][is_new]`, `${tag.is_new ? 1 : 0}`);
            if (tag.is_new === true) {
              bodyFormData.append(`tags[${i}][tag_title]`, `${tag.tag_title}`);
            }
            bodyFormData.append(
              `tags[${i}][tag_id]`,
              `${tag.tag_id ? tag.tag_id : ""}`
            );
          });
          bodyFormData.append(`vendor`, this.vendor);
          bodyFormData.append(`firstname`, this.firstname);
          bodyFormData.append(`lastname`, this.lastname);
          bodyFormData.append(`email`, this.email);
          bodyFormData.append(`mobile`, this.phonenumber);
          bodyFormData.append(`should_invite`, "1");

          const options = {
            method: "POST",
            data: bodyFormData,
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_BASEURL + `/vendors`,
          };
          this.$http(options)
            .then((res) => {
              //   this.sidebar_bulkupdate = false;
              this.closeSidebar();
              this.getVendorsList();
              this.vendor = "";
              this.firstname = "";
              this.lastname = "";
              this.email = "";
              this.phonenumber = "";
              this.tagName = "";
              this.formData.category.selectedCategoryName = "";
              this.formData.selectedTags = [];
              this.formData.category.selectedCategories = [];
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    createLegalRegister() {
      const data = {
        title: this.title,
        requirement: this.content,
        authority: this.authority,
        internal_poc_user_id: this.internalpoc,
        type: this.type,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers`,
      };
      this.$http(options)
        .then((res) => {
          //   this.sidebar_bulkupdate = false;
          this.closeSidebar();
          this.getLegalList();
          this.title = "";
          this.content = "";
          this.dateDefault = "";
          this.authority = "";
          this.internalpoc = "";
          this.type = "";
          this.country = "";
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateLegalRegister() {
      const data = {
        title: this.title,
        requirement: this.content,
        effective_from: this.dateDefault,
        authority: this.authority,
        internal_poc_user_id: this.internalpoc,
        type: this.type,
        country: this.country,
      };
      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers/${this.legalid}`,
      };
      this.$http(options)
        .then((res) => {
          //   this.sidebar_bulkupdate = false;
          this.closeEditLegal();
          this.getLegalList();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUsers() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/users?dont_paginate=true`,
      };
      this.$http(options)
        .then((res) => {
          this.userslist = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getVendorsList(pageno) {
      this.showOverlay = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/vendors`,
        params: { page: pageno },
      };
      this.$http(options)
        .then((res) => {
          this.vendorslist = res.data.data.data;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows = res.data.data.last_page * 10;
          this.currentPage = res.data.data.current_page;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err)
        })
        .finally(() => {
          this.showOverlay = false
        })
    },
    getLegalDetails() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers/${this.legalid}`,
      };
      this.$http(options)
        .then((res) => {
          this.legaldetails = res.data.data;
          this.title = this.legaldetails.title;
          this.content = this.legaldetails.requirement;
          this.dateDefault = this.legaldetails.effective_from;
          this.authority = this.legaldetails.authority;
          this.internalpoc = this.legaldetails.internal_poc_user_id;
          this.type = this.legaldetails.type;
          this.country = this.legaldetails.country;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteLegal() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers/${this.legalid}`,
      };
      this.$http(options)
        .then((res) => {
          this.getLegalList();
          this.hideModal();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openEditLegal(id) {
      this.legalid = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
      this.getLegalDetails();
    },
    closeEditLegal() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
    },

    handleRowClick(assessmentId) {
      this.$router.push(`/riskassessment/${assessmentId}/risks`);
    },

    getRiskAssessmentsAndSetData(params) {
      this.getRiskAssessments(params)
        .then((res) => {
          this.assessments = res.data.data.data;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows = res.data.data.last_page * 10;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleNewRiskAssessmentModalSubmit() {
      this.storeRiskAssessment({
        title: this.newRiskAssessment.title,
        notes: this.newRiskAssessment.notes,
      })
        .then((res) => {
          this.load();
          this.handleResponse(res);
          // console.log("created", res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.hideModal();
        });
    },

    handleNewRiskAssessmentClick() {
      this.newRiskAssessment.title = null;
      this.newRiskAssessment.notes = null;
      this.showModal();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    showModal(id) {
      this.legalid = id;
      this.$refs["my-modal"].show();
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    closeAddVendor() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      this.vendor = "";
      this.firstname = "";
      this.lastname = "";
      this.email = "";
      this.phonenumber = "";
      this.tagName = "";
      this.formData.category.selectedCategoryName = "";
      this.formData.selectedTags = [];
      this.formData.category.selectedCategories = [];
    },
    openSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>

<style lang="css" scoped>
.table td,
.table th {
  width: 10%;
  text-transform: capitalize !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
th {
  text-transform: capitalize !important;
}
</style>
